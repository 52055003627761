import { useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

export default function TermsAndConditions() {
	const [darkMode, setDarkMode] = useState(false);

	return (
		<div className={darkMode ? 'dark' : ''}>
			<div className="bg-white dark:bg-gray-800 px-6 py-32 lg:px-8">
				<div className="mx-auto max-w-3xl text-base leading-7 text-gray-700 dark:text-gray-300">
					<h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
						Terms and Conditions
					</h1>
					<p className="mt-2 text-sm text-gray-500">Last Updated: February 16, 2025</p>
					<p className="mt-6 text-xl leading-8">
						These Terms and Conditions ("Terms") constitute a legally binding agreement between you ("User") and TrackNList ("Company," "we," "us," or "our") regarding your access to and use of our website and services (the "Service"). By accessing or using our Service, you agree to be bound by these Terms. If you do not agree, please discontinue use immediately.
					</p>

					{/* Agreement to Terms */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Agreement to Terms
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						By accessing our Service, you confirm that you have read, understood, and agree to be bound by these Terms, including any supplemental terms incorporated herein. Continued use of the Service constitutes your acceptance of any changes made to these Terms.
					</p>

					{/* Intellectual Property */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Intellectual Property
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						All content, trademarks, and other intellectual property on the Service are owned or licensed by us. No portion of the Service or its Content may be reproduced, distributed, or exploited for any commercial purpose without our prior written consent.
					</p>

					{/* User Representation */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						User Representation
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						You represent and warrant that you have the legal capacity to enter into these Terms and will comply with all applicable laws and regulations. Unauthorized access or use of the Service is strictly prohibited.
					</p>

					{/* User-Generated Content */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						User-Generated Content
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						The Service may allow you to submit content, including but not limited to text, images, and other media ("Contributions"). By submitting Contributions, you grant us a non-exclusive, royalty-free license to use, display, and distribute such content. You are solely responsible for your Contributions and warrant that they do not infringe on any third-party rights.
					</p>

					{/* Term and Termination */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Term and Termination
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						These Terms shall remain in effect while you use the Service. We reserve the right to suspend or terminate your access at our sole discretion, without prior notice, for any violation of these Terms.
					</p>

					{/* Modifications and Interruptions */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Modifications and Interruptions
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						We may modify or discontinue the Service, in whole or in part, at any time without notice. We will not be liable for any modifications, interruptions, or discontinuations of the Service.
					</p>

					{/* Governing Law and Dispute Resolution */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Governing Law and Dispute Resolution
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						These Terms shall be governed by and construed in accordance with the laws of the applicable jurisdiction. Any disputes arising from these Terms shall be resolved through binding arbitration in accordance with applicable arbitration rules.
					</p>

					{/* Disclaimer and Limitation of Liability */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Disclaimer and Limitation of Liability
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						The Service is provided on an "as is" and "as available" basis without warranties of any kind. To the fullest extent permitted by law, we disclaim all warranties, whether express or implied, and will not be liable for any damages arising from your use of the Service.
					</p>

					{/* Indemnification */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Indemnification
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						You agree to indemnify and hold TrackNList and its affiliates harmless from any claims, damages, or losses arising out of your use of the Service or any violation of these Terms.
					</p>

					{/* Electronic Communications */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Electronic Communications
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						By using our Service, you consent to receive electronic communications from us, which may include notices, agreements, or other documents that satisfy legal requirements.
					</p>

					{/* Miscellaneous */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Miscellaneous
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						These Terms constitute the entire agreement between you and TrackNList regarding the Service and supersede any prior agreements. If any provision is deemed unenforceable, the remaining provisions will continue in full force and effect.
					</p>

					{/* Contact Us */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Contact Us
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						If you have any questions or concerns regarding these Terms, please contact us at{' '}
						<a
							href="mailto:support@trackandlist.com"
							className="text-indigo-600 dark:text-indigo-400 underline"
						>
							support@trackandlist.com
						</a>.
					</p>

					<figure className="mt-10 border-l border-indigo-600 dark:border-indigo-400 pl-9">
						<blockquote className="font-semibold text-gray-900 dark:text-white">
							<p>
								“By using our Service, you acknowledge and agree to these Terms and Conditions.”
							</p>
						</blockquote>
						<figcaption className="mt-6 flex gap-x-4">
							<div className="text-sm leading-6">
								<strong className="font-semibold text-gray-900 dark:text-white">
									The TrackNList Team
								</strong>
							</div>
						</figcaption>
					</figure>
					<p className="mt-10">
						Thank you for choosing TrackNList.
					</p>
				</div>
			</div>
		</div>
	);
}
