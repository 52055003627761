import { useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

export default function PrivacyPolicy() {
	const [darkMode, setDarkMode] = useState(false);

	return (
		<div className={darkMode ? 'dark' : ''}>
			<div className="bg-white dark:bg-gray-800 px-6 py-32 lg:px-8">
				<div className="mx-auto max-w-3xl text-base leading-7 text-gray-700 dark:text-gray-300">
					<h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
						Privacy Policy
					</h1>
					<p className="mt-2 text-sm text-gray-500">Last Updated: February 16, 2025</p>
					<p className="mt-6 text-xl leading-8">
						This Privacy Policy describes our policies and procedures regarding the collection, use, and disclosure of your information when you use the TrackNList Service. By accessing or using our Service, you agree to the practices described herein.
					</p>

					{/* Interpretation and Definitions */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Interpretation and Definitions
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						In this Policy, "Account" means your unique user account on TrackNList; "Service" refers to the TrackNList platform; and "You" means the individual accessing or using our Service.
					</p>

					{/* Collecting and Using Your Personal Data */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Collecting and Using Your Personal Data
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						We collect and use your personal data to provide and improve our Service. This may include:
					</p>
					<ul className="mt-4 list-disc pl-6 text-gray-600 dark:text-gray-400 space-y-2">
						<li>
							<strong>Personal Data:</strong> such as your mail, email address, name, and contact details.
						</li>
						<li>
							<strong>Usage Data:</strong> automatically-collected data, including your IP address, browser type, and pages visited.
						</li>
					</ul>

					{/* Google User Data Section */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Google User Data
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						To deliver our core functionality and enhance your experience, our Service may access, use, store, or process data from your Google Account as outlined below.
					</p>
					<ul role="list" className="mt-6 max-w-xl space-y-4 text-gray-600 dark:text-gray-400 list-disc pl-5">
						<li>
							<strong>Access and Authentication:</strong> We access your Google Account information to enable secure sign-in and provide seamless integration with our service.
						</li>
						<li>
							<strong>Service Enhancement:</strong> Usage data from your Google Account is employed to analyze service utilization and improve your overall experience.
						</li>
						<li>
							<strong>Legal Compliance:</strong> Certain Google user data, such as purchase history, may be stored solely to satisfy our legal and regulatory obligations.
						</li>
						<li>
							<strong>Data Retention:</strong> For email data that is not amenable to parsing from supported platforms, such data will be retained only until a parsing algorithm is developed, after which it will be permanently deleted. For emails that are fully parsable, we do not store or retain the email content; however, we continuously maintain your user access token exclusively for accessing new mail.
						</li>
						<li>
							<strong>Data Sharing:</strong> In adherence with our commitment to privacy, we do not share, transfer, or disclose your Google user data with any unauthorized third parties.
						</li>
					</ul>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						Limited Use Disclosure: Our use and transfer of information obtained from Google APIs are strictly governed by the Google API Services User Data Policy, including its Limited Use requirements. Should we intend to use Google user data in a manner not described in this Policy, we will first obtain your explicit consent.
					</p>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						You retain the right to access, correct, or delete your Google user data at any time. Additionally, you may opt out of the collection or use of your Google user data by contacting us.
					</p>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						Furthermore, if you choose to delete your TrackNList account, all associated data—including inventories, listings, retained email data, and connected user accounts—will be permanently and irrevocably removed.
					</p>

					{/* Tracking Technologies and Cookies */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Tracking Technologies and Cookies
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						We employ cookies and similar tracking technologies to monitor activity on our Service and enhance user experience. You may manage your cookie preferences via your browser settings.
					</p>

					{/* Retention and Deletion */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Retention and Deletion of Your Personal Data
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						Your personal data is retained only for as long as necessary to fulfill the purposes outlined in this Policy. You may access, correct, or request deletion of your data at any time. If you delete your TrackNList account, all associated data—including inventories, listings, and any retained email data—will be permanently removed.
					</p>

					{/* Security */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Security of Your Personal Data
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						We employ commercially reasonable measures to protect your personal data. However, no method of transmission or storage is completely secure.
					</p>

					{/* Children's Privacy */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Children's Privacy
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						Our Service is not intended for individuals under the age of 13, and we do not knowingly collect data from children under 13.
					</p>

					{/* Changes to this Privacy Policy */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Changes to This Privacy Policy
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						We may update this Policy from time to time. Any changes will be effective immediately upon posting on this page, and the "Last Updated" date will be revised accordingly. We encourage you to review this Policy periodically.
					</p>

					{/* Contact Us */}
					<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
						Contact Us
					</h2>
					<p className="mt-6 text-gray-600 dark:text-gray-400">
						If you have any questions or concerns regarding this Privacy Policy, please contact us at <a href="mailto:support@trackandlist.com" className="text-indigo-600 dark:text-indigo-400 underline">support@trackandlist.com</a>.
					</p>

					<figure className="mt-10 border-l border-indigo-600 dark:border-indigo-400 pl-9">
						<blockquote className="font-semibold text-gray-900 dark:text-white">
							<p>
								“Your privacy is of paramount importance to us. We are committed to protecting your personal data and ensuring full transparency in our practices.”
							</p>
						</blockquote>
						<figcaption className="mt-6 flex gap-x-4">
							<div className="text-sm leading-6">
								<strong className="font-semibold text-gray-900 dark:text-white">
									The TrackNList Team
								</strong>
							</div>
						</figcaption>
					</figure>
					<p className="mt-10">
						Thank you for placing your trust in TrackNList.
					</p>
				</div>
			</div>
		</div>
	);
}
